<template>
    <breadcrumb btnText="返回" btnIcon="n-direction-left" @btn-click="$router.go(-1)" />

    <div class="page-article-create" v-if="!result.show">
    
        <article-form :category="category" :btnLoading="loading" :data="article" @submit="submit" />

    </div>
    <operation-result
        v-model:show="result.show"
        :duration="result.duration"
        :title="result.title"
        :status="result.status"
    />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ArticleForm from '@/components/forms/ArticleForm.vue';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import OperationResult from '@/components/layout/OperationResult.vue';

@Options({
    components: {
        ArticleForm, Breadcrumb, OperationResult
    },
    data(){
        return {
            category: {},
            article: {},
            result: {
                show: false,
                title: '',
                duration: 0,
                toTitle: '',
                status: 'SUCCESS'
            },
            loading: false
        }
    },
    mounted(){
        if(this.$route.params.id)
            this.getCategory(Number(this.$route.params.id));
        else this.$router.go(-1);
        this.getArticle(Number(this.$route.params.article_id));
    },
    methods: {
        getCategory(id: number){
            this.$api.xrCategoryShow(id).then((res: any) => {
                console.log(res)
                this.category = {
                    id: res.data.id,
                    title: res.data.title,
                    type: res.data.type
                };
            })
        },
        getArticle(id: number){
            this.$api.xrArticleShow(id).then((res: any) => {
                console.log(res)
                let type: object = {},
                    status: object = {},
                    permissions: object = {},
                    content = '',
                    published_at = new Date(res.data.published_at);
                if(res.data.type === 'page') {
                    type = {
                        label: '单页面',
                        value: 'page'
                    };
                    content = res.data.content;
                } else if(res.data.type === 'equipment') {
                    type = {
                        label: '产品列表',
                        value: 'equipment'
                    };
                } else {
                    type = {
                        label: '文章列表',
                        value: 'article'
                    };
                }

                if(res.data.status === 'published'){
                    status = {
                        label: '发布',
                        value: 'published'
                    }
                } else if(res.data.status === 'draft'){
                    status = {
                        label: '草稿',
                        value: 'draft'
                    }
                } else {
                    status = {
                        label: '回收站',
                        value: 'deleted'
                    }
                }

                if(res.data.permissions === 'public'){
                    permissions = {
                        label: '公开',
                        value: 'public'
                    }
                } else {
                    permissions = {
                        label: '私有',
                        value: 'private'
                    }
                }

                this.article = {
                    id: res.data.id,
                    cat_id: res.data.cat_id,
                    title: res.data.title,
                    author: res.data.author,
                    keywords: res.data.keywords,
                    description: res.data.description,
                    status: status,
                    permissions: permissions,
                    thumb: res.data.thumb,
                    published_at: {
                        date: {
                            year: published_at.getFullYear(),
                            month: published_at.getMonth()+1 < 10 ? `0${published_at.getMonth()+1}` : published_at.getMonth()+1,
                            day: published_at.getDate() < 10 ? `0${published_at.getDate()}` : published_at.getDate()
                        },
                        time: {
                            hour: published_at.getHours() < 10 ? `0${published_at.getHours()}` : published_at.getHours(),
                            minute: published_at.getMinutes() < 10 ? `0${published_at.getMinutes()}` : published_at.getMinutes(),
                            second: published_at.getSeconds() < 10 ? `0${published_at.getSeconds()}` : published_at.getSeconds()
                        }
                    },
                    content: res.data.content.content
                }
            })
        },
        submit(e: any){
            this.loading = true;
            this.$api.xrArticleUpdate(Number(e.id), e).then((res: any) => {
                
                this.loading = false;
                if(res.msg === 'success'){
                    this.result = {
                        show: true,
                        title: '文章更新成功',
                        duration: 0,
                        status: 'SUCCESS'
                    }
                } else {
                    this.result = {
                        show: true,
                        title: '文章更新失败，接口报错，请联系技术人员检查',
                        duration: 0,
                        status: 'ERROR'
                    }
                }
            })
        }
    }
})

export default class ArticlesEdit extends Vue {}
</script>

<style lang="scss" scoped>
// .page-article-create{
     
// }
</style>